<template>
  <div>
    <div v-if="!data_loaded">
      <v-skeleton-loader
        v-for="a in 5"
        :key="a"
        type="article"
      ></v-skeleton-loader>
    </div>

    <v-form ref="form">
      <Base :settings="settings" :data_loaded="data_loaded" />
      <closedMsg :settings="settings" :data_loaded="data_loaded" />
      <work_times
        :work_times="settings.work_times"
        :data_loaded="data_loaded"
      />
    </v-form>
    <v-col class="ma-4" v-if="data_loaded">
      <v-btn
        v-if="$store.getters.permission('settings edit')"
        @click="save()"
        :disabled="save_loader"
        :loading="save_loader"
        height="40"
        color="black white--text"
        >שמירה</v-btn
      >
    </v-col>
    <v-snackbar left v-model="snackbar">
      <p class="white--text">ההגדרות עודכנו בהצלחה</p>
      <template v-slot:action>
        <v-icon color="green" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Base from "@/components/settings/general/base";
import work_times from "@/components/settings/general/work_times";
import closedMsg from "@/components/settings/general/closedMsg";

export default {
  name: "general",
  data() {
    return {
      snackbar: false,
      data_loaded: false,
      save_loader: false,
      settings: {
        android_version: null,
        android_link: null,
        ios_version: null,
        ios_link: null,
        note: null,
        show_search_barcode: false,
        primaryColor: "#4aabff",
        logo: null,
        work_times: [
          {
            day: "יום ראשון",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
          {
            day: "יום שני",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
          {
            day: "יום שלישי",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
          {
            day: "יום רביעי",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
          {
            day: "יום חמישי",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
          {
            day: "יום שישי",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
          {
            day: "יום שבת",
            from: "13:41",
            to: "17:23",
            can_delete: 0,
          },
        ],
      },
    };
  },
  components: {
    Base,
    work_times,
    closedMsg,
  },
  methods: {
    get_settings() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "settings",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          const data = res.data.data;

          if (data.work_times.length == 0) {
            data.work_times = this.settings.work_times;
          }
          this.settings = Object.assign({}, data);
        });
    },
    pareData() {
      const formData = new FormData();
      Object.entries(this.settings).forEach((e) => {
        if (e[0] != "work_times") {
          formData.append(e[0], e[1] || "");
        } else {
          e[1].forEach((w, i) => {
            formData.append(`work_times[${i}][day]`, w.day);
            formData.append(`work_times[${i}][from]`, w.from);
            formData.append(`work_times[${i}][to]`, w.to);
            formData.append(`work_times[${i}][can_delete]`, w.can_delete);
          });
        }
      });

      return  formData;
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;

        this.$store
          .dispatch("public__request", {
            config: {
              url: "settings",
              method: "post",
            },
            data: this.pareData(),
          })
          .then((res) => {
            this.get_settings();
            this.save_loader = false;
            this.snackbar = true;

            if (this.settings.show_closed_msg && this.settings.closed_msg) {
              this.$router.push(`/messages?msg=${this.settings.closed_msg}`);
            }
          });
      }
    },
  },
  mounted() {
    this.get_settings();
  },
};
</script>

<style lang="scss" scoped>
// .basic {
//   .col {
//     flex: 0 0 20%;
//     max-width: 20%;
//     @media (max-width: 991px) {
//       flex: 0 0 50%;
//       max-width: 50%;
//     }
//   }
// }

::v-deep {
  .color {
    min-width: 100px;
    padding-top: 0;
    input {
      padding: 4px !important;
    }
    .v-input__slot:after,
    .v-input__slot:before {
      display: none !important;
    }
  }
  .v-application--is-rtl
    .v-textarea.v-text-field--enclosed
    .v-text-field__slot {
    min-width: 100px !important;
  }
}
</style>
