<template>
  <div>
    <v-card v-if="data_loaded">
      <v-card-text>
        <v-row v-for="(day, i) in work_times" :key="i">
          <v-col cols="2" sm="1" class="font-weight-bold pt-5">{{
            day.day
          }}</v-col>
          <v-col cols="10" sm="4" class="d-flex">
            <v-text-field
              class="ml-2"
              outlined
              label="מ"
              type="time"
              hide-details
              v-model="day.from"
            ></v-text-field>
            <v-text-field
              type="time"
              outlined
              hide-details
              label="עד"
              v-model="day.to"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex"
            :class="[{ 'justify-space-between': day.can_delete }]"
          >
            <v-btn
              v-if="$store.getters.permission('settings edit')"
              width="49%"
              @click="insert_day(i, day)"
              height="48"
              color="primary"
              outlined
            >
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="
                day.can_delete && $store.getters.permission('settings delete')
              "
              @click="delete_day(i)"
              height="48"
              color="error"
              class="ms-2"
              width="49%"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["work_times", "data_loaded"],
  name: "work_times",
  methods: {
    insert_day(index, d) {
      const day = {
        day: d.day,
        from: "17:23:00",
        to: "17:23:00",
        can_delete: 1,
      };
      this.work_times.splice(index + 1, 0, day);
    },
    delete_day(index) {
      this.work_times.splice(index, 1);
    },
  },
};
</script>
