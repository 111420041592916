<template>
  <div>
    <v-card class="mb-10" v-if="data_loaded">
      <v-card-title>
        <div>
          <v-icon x-large>settings</v-icon>
          <h1 class="ms-3">הגדרות</h1>
        </div>
        <v-spacer></v-spacer>
        <v-btn text exact to="/settings" color="primary">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-form ref="form">
          <v-row class="mt-2">
            <v-col class="py-0" cols="3">
              <v-text-field
                v-model="settings.android_version"
                outlined
                dense
                label="גרסת אנדרויד"
              ></v-text-field>
            </v-col>

            <v-col class="py-0" cols="3">
              <v-text-field
                v-model="settings.android_link"
                outlined
                dense
                label="קישור אנדרויד"
              ></v-text-field>
            </v-col>

            <v-col class="py-0" cols="3">
              <v-text-field
                v-model="settings.ios_version"
                outlined
                dense
                label="גרסת אייפון"
              ></v-text-field>
            </v-col>

            <v-col class="py-0" cols="3">
              <v-text-field
                v-model="settings.ios_link"
                outlined
                dense
                label="קישור אנדרויד"
              ></v-text-field>
            </v-col>

            <v-col class="py-0" cols="3">
              <v-text-field
                v-model="settings.primaryColor"
                outlined
                type="color"
                dense
                class="color"
                label="primaryColor"
              ></v-text-field>
            </v-col>

            <v-col class="py-0" cols="3">
              <v-file-input
                v-model="settings.logo"
                outlined
                dense
                label="logo"
              ></v-file-input>
            </v-col>
            <!-- <v-col class="py-0" cols="3">
                  <v-text-field
                    v-model="settings.email"
                    outlined
                    dense
                    label="email"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="3">
                  <v-text-field
                    outlined
                    dense
                    label="phone_number"
                    v-model="settings.phone_number"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="3">
                  <v-text-field
                    v-model="settings.fax"
                    outlined
                    dense
                    label="fax"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="3">
                  <v-text-field
                    v-model="settings.address"
                    outlined
                    dense
                    label="address"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="3">
                  <v-text-field
                    v-model="settings.country"
                    outlined
                    dense
                    label="country"
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" cols="3">
                  <v-text-field
                    type="color"
                    outlined
                    v-model="settings.primary_color"
                    dense
                    class="color"
                    label="primary color"
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" cols="3">
                  <v-file-input
                    outlined
                    dense
                    v-model="settings.logo"
                    label="logo"
                    prepend-icon=""
                    prepend-inner-icon="attach_file"
                  ></v-file-input>
                </v-col>

                <v-col class="py-0" cols="12">
                  <v-textarea
                    v-model="settings.message"
                    outlined
                    label="message"
                  >
                    <template slot="append">
                      <v-text-field
                        class="color"
                        outlined
                        dense
                        v-model="settings.message_color"
                        type="color"
                      ></v-text-field>
                    </template>
                  </v-textarea>
                </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Base",
  props: ["settings", "data_loaded"],
  data() {
    return {};
  },
};
</script>
